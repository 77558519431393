<template>
  <div class="header-push" style="--el-tag-bg-color: red">
    <el-menu mode="horizontal" :ellipsis="false" style="height: 56px;"
             active-text-color="#42b983" ref="refMenu" :router="true" :default-active="activeIndex">

      <!--左-->
      <el-menu-item style="padding: 0;">
        <a href="/">
          <el-image class="logo-image" fit="fill"
                    :src="require('@/assets/images/logo-default-1.png')"/>
        </a>
      </el-menu-item>

      <el-menu-item index="/about">
        <router-link class="no-underline" to="/about">关于我们</router-link>
      </el-menu-item>
<!--      <el-menu-item>
        <el-link icon="Connection" :underline="false" href="https://aliyun.com/" target="_blank">Ali</el-link>
      </el-menu-item>
      <el-menu-item>
        <el-link icon="Link" :underline="false" href="https://www.baidu.com" target="_blank">Baidu</el-link>
      </el-menu-item>-->

      <div class="flex-grow"/>

      <!--右-->
      <el-menu-item @click="toggleDark">
        <Sunny v-if="isDark" style="width: 18px; height:18px;" color="white"/>
        <MoonNight v-else style="width: 18px; height:18px;"/>
      </el-menu-item>

<!--      <el-sub-menu :index="item.value" v-for="(item,index) in demoList" :key="index">
        <template #title>{{ item.label }}</template>
        <el-menu-item :index="itemTwo.value" v-for="(itemTwo,indexTwo) in item.child" :key="indexTwo">
          {{ itemTwo.label }}
        </el-menu-item>
      </el-sub-menu>-->


    </el-menu>
  </div>
</template>

<script>
import {useDark, useToggle} from "@vueuse/core";

export default {
  name: "Header",
  components: {},

  data() {
    return {
      activeIndex: '',
      themeChalkDark: false,
      isDark: useDark(),

      demoList: [
        {
          label: 'demo', value: '/demo',
          child: [
            {label: 'theme', value: '/demo/theme'},
            {label: 'color-card', value: '/demo/color-card'},
            {label: 'glass-card', value: '/demo/glass-card'},
            {label: 'text-underline', value: '/demo/text-underline'},
          ]
        }
      ]
    }
  },
  created() {
    this.activeIndex = window.location.pathname;
  },
  watch: {
    activeIndex(newValue, oldValue) {
    },
    /**
     * 监听路由，刷新选中信息
     * @param to
     * @param from
     */
    $route(to, from) {
      this.activeIndex = to.path
    }
  },
  methods: {
    toggleDark() {
      this.isDark = !this.isDark
      useToggle(this.isDark)
    },
  }
}
</script>

<style lang="scss">
.header-push {
  margin-left: auto;
  margin-right: auto;
  max-width: 1440px;
  width: calc(100% - 40px);
}

.no-underline {
  text-decoration: none; /* 去除下划线 */
}

.flex-grow {
  flex-grow: 1;
}

.logo-image {
  width: 56px;
  height: 56px;
}


/* 禁止文本选择 */
.el-menu, .no-underline {
  user-select: none;
}


/*去除 el-menu 底部透明下划线*/
.el-menu--horizontal.el-menu {
  //border-bottom: 0 !important;
}

/*鼠标悬浮背景颜色*/
.el-menu-item:hover {
  //background-color: var(--el-menu-hover-bg-color);
  background-color: #42b983 !important;
}

/*el-menu-item - 鼠标背景颜色*/
.el-menu--horizontal .el-menu-item:not(.is-disabled):focus, .el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
  //color: var(--el-menu-hover-text-color);
  color: #42b983 !important; //待选中
  background-color: rgba(66, 187, 132, 0) !important; //待选中/选中 背景色块
}

/*el-menu-item - 鼠标背景颜色*/
.el-menu--horizontal .el-sub-menu:hover, .el-sub-menu__title:hover {
  //color: var(--el-menu-hover-text-color);
  color: #42b983 !important; //待选中
  background-color: rgba(66, 187, 132, 0) !important; //待选中/选中 背景色块
}

</style>

