import axios from '@/api/axios'

export function add(data) {
    return axios({
        url: 'api/consult/add',
        method: 'POST',
        headers: {
            // Authorization: token,
        },
        data: data
    })
}
