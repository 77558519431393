<template>
  <div class="home">
    <HomeIntroduce msg="Welcome to Your Vue.js App"/>
    <HomeServe/>
    <HomeFooter/>
  </div>
</template>

<script>
// @ is an alias to /src
import HomeIntroduce from '@/components/Home/HomeIntroduce'
import HomeServe from '@/components/Home/HomeServe'
import HomeFooter from '@/components/Home/HomeFooter'

export default {
  name: 'HomeView',
  components: {
    HomeIntroduce,
    HomeServe,
    HomeFooter,
  },
  data() {
    return {}
  }
}
</script>
