<template>
  <div style="margin-left: auto;margin-right: auto;max-width: 1440px;width: calc(100% - 40px);">
    <el-divider/>
  </div>
  <div class="container">
    <a href="https://beian.miit.gov.cn/" target="_blank">蜀ICP备2024068822号</a>
  </div>
</template>

<script>
export default {
  //首页页脚
  name: "HomeFooter"
}
</script>

<style scoped>

/*页面内容*/
.container {
  margin: 8px;
  /*margin-bottom: 5px;*/
  opacity: 0.5;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  flex-wrap: wrap;
}

.container a {
  text-decoration: none;
}

.el-divider--horizontal {
  margin: 5px 0;
}
</style>
