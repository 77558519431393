import axios from "axios";
import 'nprogress/nprogress.css'

const request = axios.create({
    baseURL: 'https://api.qszh.cloud/',//本地接口
    // baseURL: 'http://localhost:9527/',//本地接口
    timeout: 10000,
})

// 请求拦截
request.interceptors.request.use(
    config => {
        const identification = window.localStorage.getItem('identification')
        //identification存在，且是基于baseURL的请求
        if (identification && !(config.url.startsWith('http://') || config.url.startsWith('https://'))) {
            config.headers.identification = identification
        }
        return config
    }
)

request.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {
        return response.data
    },
    error => {
        return error.response.data
    }
)
export default request
