<template>
  <div>
    <h1 style="font-size: 35px;line-height: 1.25em;">技术服务</h1>
    <div style="width: 1200px;opacity: 0.5;margin: auto auto 80px;text-indent: 2em;">
      无论您面临的是技术挑战还是业务发展需求，我们都将竭诚为您提供最佳的解决方案，助您取得成功！
    </div>
  </div>

  <div class="container">
    <div class="glass-card" v-for="(item,index) in server" :key="index" ref="card">
      <img :src="item.image" alt=""/>
      <div class="content">
        <h2 class="title">{{ item.title }}</h2>
        <div class="desc">
          <p>{{ item.desc }}</p>
          <a href="#" @click="to(item.title)">咨询服务</a>
        </div>
      </div>
    </div>

  </div>


  <!--提交表单-->
  <el-dialog v-model="dialogFormVisible" title="咨询表单" width="400" center :before-close="handleDialogBeforeClose">
    <el-form :model="form" ref="dialogForm" label-position="top" :rules="rules">

      <el-form-item label="称呼" prop="name" label-width="140px">
        <el-input v-model="form.name" autocomplete="off" placeholder="我们该怎么称呼你呢"/>
      </el-form-item>
      <el-form-item label="手机号" prop="contactInformation" label-width="140px">
        <el-input v-model="form.contactInformation" autocomplete="off" placeholder="一般来说我们使用的是手机号">
          <template #append>
            <el-button :disabled="countDown > 0" @click="sendCode">
              {{ countDown > 0 ? countDown + 's 后重新发送' : '发送验证码' }}
            </el-button>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item label="验证码" prop="code" label-width="140px">
        <el-input v-model="form.code" autocomplete="off" placeholder="我们要确定这个手机号您在使用"/>
      </el-form-item>
      <!--      <el-form-item label="咨询类型" prop="contactType " label-width="140px">
              <el-input v-model="form.contactType " autocomplete="off" placeholder="我们好让对应专业同事联系您"/>
            </el-form-item>-->

    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="closeDialog">取消</el-button>
        <el-button contactType="primary" :disabled="!dialogFormVisible" @click="submit">
          确定
        </el-button>
      </div>
    </template>
  </el-dialog>


</template>

<script>
import VanillaTilt from "vanilla-tilt";
import {sendCode} from "@/api/sms";
import {add} from "@/api/consult";

export default {
  name: "HomeServe",
  data() {
    return {
      // 图标地址 https://www.iconfont.cn/illustrations/detail?cid=28713
      //服务
      server: [
        {
          title: '咨询服务',
          image: require('@/assets/images/home/serve/咨询.svg'),
          desc: '我们拥有丰富的经验和专业知识，在信息技术领域为客户提供全方位的咨询服务，帮助他们解决各种技术难题。'
        },
        {
          title: '开发服务',
          image: require('@/assets/images/home/serve/软件开发.svg'),
          desc: '我们的软件开发团队拥有多年的开发经验，能够为客户量身定制各种类型的软件，满足他们的个性化需求。'
        },
        // {
        //   title: '网络安全',
        //   image: require('@/assets/images/home/serve/猫-2.gif'),
        //   desc: '我们致力于为客户提供可靠的网络与信息安全软件解决方案，保护他们的网络和信息安全不受威胁。'
        // },
        // {
        //   title: '人工智能',
        //   image: require('@/assets/images/home/serve/猫-3.gif'),
        //   desc: '我们专注于人工智能技术的研发和应用，为客户提供智能化的软件解决方案，提升他们的业务效率和智能化水平。'
        // },
        {
          title: '系统服务',
          image: require('@/assets/images/home/serve/系统服务.svg'),
          desc: '我们提供系统设计、部署、优化和维护等计算机系统服务，确保客户的计算机系统运行稳定高效。'
        },
        {
          title: '数据服务',
          image: require('@/assets/images/home/serve/数据处理.svg'),
          desc: '我们提供高效可靠的数据处理服务，帮助客户处理和分析海量数据，发掘数据背后的价值和意义。'
        },
        // {
        //   title: '大数据服务',
        //   image: require('@/assets/images/home/serve/猫-0.gif'),
        //   desc: '我们专注于大数据技术的研究和应用，为客户提供全方位的大数据解决方案，助力他们实现数据驱动的业务发展。'
        // },
      ],
      dialogFormVisible: false,
      // 提交内容
      form: {
        // 称呼
        name: undefined,
        // 手机号
        contactInformation: undefined,
        // 验证码
        code: undefined,
        // 联系方式
        contactType: 0,
        // 咨询内容
        value: 0,
      },
      // 角色
      rules: {
        name: [{required: true, message: '路人甲？', trigger: 'blur'},],
        contactInformation: [{required: true, message: '道友可是要我等夜观天象来寻！', trigger: 'blur'},],
        code: [{required: true, message: '所以说验证码到底是什么呢？', trigger: 'blur'},],
        contactType: [{required: true, message: '小孩子才做选择，我全都要', trigger: 'blur'},],
      },
      // 初始倒计时秒数
      countDown: 0,
      // 定时器
      timer: null,
    }
  },
  /**
   * 页面创建执行
   */
  mounted() {
    this.init()
  },
  /**
   * 执行方法
   */
  methods: {
    /**
     * 初始化
     */
    init() {
      VanillaTilt.init(this.$refs.card, {
        max: 0.8,  //最大倾斜度数
        speed: 20,  //倾斜转换的速度
        glare: true,  //是否开启眩光效果
        "max-glare": 0.1  //最大眩光的不透明度
      });
    },
    /**
     * 展示咨询提交表单
     * @param title 展示内容
     */
    to(title) {
      if (true) {
        this.$message.warning('忙的飞起，不接客了')
        return
      }
      this.form = {
        // 称呼
        name: undefined,
        // 手机号
        contactInformation: undefined,
        // 验证码
        code: undefined,
        // 联系方式
        contactType: 0,
        // 咨询内容
        value: title,
      }
      this.dialogFormVisible = true
      // this.$message.success(title)
    },
    handleDialogBeforeClose(done) {
      // 在对话框关闭前调用 clearValidate() 方法清除表单验证状态
      this.$nextTick(() => {
        this.$refs.dialogForm.clearValidate();
        this.clearIntervalTimer()
        done();
      });
    },
    /**
     * 关闭弹窗
     */
    closeDialog() {
      this.dialogFormVisible = false
      this.$refs.dialogForm.clearValidate();
    },
    /**
     * 提交
     */
    submit() {
      this.$refs.dialogForm.validate((valid, fields) => {
        if (valid) {
          add(this.form).then(res => {
            console.log('add', res)
            if (res['code'] === 200) {
              // 关闭弹窗
              this.dialogFormVisible = false
              this.$message.success("提交成功")
              this.clearIntervalTimer()
            } else {
              this.$message.error(res && res['message'] ? res['message'] : "提交失败")
            }
          }).catch((res) => {
            console.log('res', res)
            this.$message.error("提交失败")
          })
        } else {
          console.log('error submit!', fields)
        }
      })
    },
    /**
     * 发送验证码
     */
    sendCode() {
      // 如果正在倒计时，直接返回
      if (this.countDown > 0) return;
      if (!this.form.contactInformation) {
        this.$message.error("手机号不能为空")
        return
      }
      sendCode(this.form.contactInformation).then(res => {
        if (res['code'] === 200) {
          this.$message.success("发送成功")
          // 发送验证码成功后，开始倒计时
          this.startCountDown();
        } else {
          this.$message.error(res && res['message'] ? res['message'] : "发送失败")
        }
      }).catch(() => {
        this.$message.error("发送失败")
      })
    },

    /**
     * 开始倒计时
     */
    startCountDown() {
      // 设置倒计时秒数为60秒
      this.countDown = 60;
      // 开始倒计时
      this.timer = setInterval(() => {
        this.countDown--;
        // 当倒计时结束时，清除定时器
        if (this.countDown === 0) {
          clearInterval(this.timer);
          this.timer = null;
        }
      }, 1000);
    },

    /**
     * 清除定时器
     */
    clearIntervalTimer() {
      this.countDown = 0
      // 组件销毁前清除定时器
      if (this.timer) {
        console.log('cl')
        clearInterval(this.timer);
      }
    },

  },
  beforeDestroy() {
    // 组件销毁前清除定时器
    this.clearIntervalTimer()
  }
}
</script>


<style scoped>

/*页面内容*/
.container {
  margin-bottom: 200px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  z-index: 1;
}

/* 玻璃效果卡片样式 */
.glass-card {
  z-index: 1; /* 设置堆叠顺序，确保元素在同一层级或更高层级显示 */
  width: 280px; /* 设置卡片的宽度 */
  height: 400px; /* 设置卡片的高度 */
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.2);

  /*position: relative; !* 设置元素的定位为相对定位，允许通过top, right, bottom, left进行调整 *!*/
  /*background-color: rgba(255, 255, 255, 0.1); !* 设置背景颜色为半透明的白色，实现玻璃效果 *!*/
  /*margin: 30px; !* 设置外边距，确保卡片与其他元素有一定的间距 *!*/
  /*border-radius: 15px; !* 设置边框圆角，提供更柔和的视觉效果 *!*/
  /*overflow: hidden; !* 超出卡片部分的内容将被隐藏 *!*/
  /*display: flex; !* 使用Flexbox布局，便于内部元素的居中对齐 *!*/
  /*justify-content: center; !* 水平居中卡片内的内容 *!*/
  /*align-items: center; !* 垂直居中卡片内的内容 *!*/
  /*border-top: 1px solid rgba(255, 255, 255, 0.5); !* 设置顶部边框为半透明的白色 *!*/
  /*border-left: 1px solid rgba(255, 255, 255, 0.5); !* 设置左侧边框为半透明的白色 *!*/
  /*backdrop-filter: blur(5px); !* 应用背景模糊滤镜，增强玻璃效果 *!*/
}


/*文本内容*/
.glass-card .content {
  padding: 20px;
  text-align: center;
  /* 默认下移 */
  transform: translateY(170px);
  transition: 0.5s;
}


/*文本内容-鼠标悬浮*/
.glass-card:hover .content {
  /* 鼠标移入，上移 */
  transform: translateY(0);
  opacity: 1;
  position: relative; /* 为文字内容设置相对定位 */
  z-index: 2; /* 确保内容总是在图片之上 */
}

/*标题*/
.glass-card .content .title {
  font-size: 28px;
  /*过度动画*/
  transition: 0.5s;
  z-index: 2; /* 初始 z-index */
}

/*标题-鼠标悬浮*/
.glass-card:hover .content .title {
  font-size: 35px;
  z-index: 3; /* 移动到上层 */
}

/*图标*/
.glass-card img {
  position: absolute; /* 绝对定位图片让它可以覆盖 */
  top: 40%; /* 将图片的顶部定位在容器的一半高度处 */
  left: 50%; /* 将图片的左侧定位在容器的一半宽度处 */
  width: 200px; /* 初始宽度，可根据需要调整 */
  /* 使用 transform 属性将图片的中心点确切定位到容器的中心 */
  transform: translate(-50%, -50%);
  /* 添加 opacity 到 transition，以及已有的属性 */
  transition: width 0.5s, height 0.5s, transform 0.5s, opacity 0.5s, filter 0.5s ease;
  z-index: 0; /* 初始 z-index */
  opacity: 1; /* 初始透明度 */
}

/*图标-鼠标悬浮*/
.glass-card:hover img {
  width: 240px; /* 鼠标悬浮时的宽度，可根据需要调整 */
  transform: translate(-50%, -50%); /* 确保图片在放大后也保持居中 */
  opacity: 0.5; /* 修改透明度 */
  filter: blur(4px); /* 鼠标悬停时的模糊效果 */
  z-index: -1; /* 移动到底层，使文字在图片上方 */
}

/*介绍中的详情*/
.glass-card .content p {
  font-size: 16px;
  font-weight: 300;
  margin: 10px 0 15px 0;
}


/*介绍*/
.glass-card .content .desc {
  /*过度动画*/
  transition: 0.5s;
  /*隐藏*/
  opacity: 0;
}

/*介绍*/
.glass-card .content .desc p {
  text-indent: 2em;
  text-align: left;
}

/*介绍-鼠标悬浮*/
.glass-card:hover .content .desc {
  /* 显示 */
  opacity: 1;
}

/*按钮*/
.glass-card .content a {
  position: relative;
  display: inline-block;
  padding: 8px 20px;
  margin-top: 15px;
  background-color: #fff;
  color: #000;
  text-decoration: none;
  border-radius: 20px;
  font-weight: 500;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}
</style>
