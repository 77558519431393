<template>
  <div>
    <!--布局容器-->
    <el-container>
      <el-header>
        <Header></Header>
      </el-header>
      <el-main>
        <router-view/>
      </el-main>
    </el-container>
  </div>
</template>


<script>
// @ is an alias to /src
import Header from '@/components/Header'

export default {
  components: {
    Header
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.el-header {
  position: fixed; // 固定位置
  top: 0; // 位于页面顶部
  left: 0; // 位于页面左侧
  width: 100%; // 宽度设置为100%，以覆盖整个页面宽度
  z-index: 1000; // 设置足够高的z-index值以确保它位于其他内容上方
  padding: 0 !important;
  height: 56px !important;
}

/* 主体内容样式，确保滚动条在el-header下面 */
.el-main {
  margin-top: 56px; /* 根据el-header的实际高度调整 */
  height: calc(100vh - 60px); /* 减去el-header的高度，确保内容区域填满剩余视口 */
  overflow-y: auto; /* 添加滚动条 */
  padding: 0 !important;
}

html::-webkit-scrollbar {
  width: 10px;
}

html::-webkit-scrollbar-track {
  background: rgb(179, 177, 177);
  border-radius: 10px;
}

html::-webkit-scrollbar-thumb {
  background: rgb(136, 136, 136);
  border-radius: 10px;
}

html::-webkit-scrollbar-thumb:hover {
  background: rgb(100, 100, 100);
  border-radius: 10px;
}

html::-webkit-scrollbar-thumb:active {
  background: rgb(68, 68, 68);
  border-radius: 10px;
}


</style>
