import axios from '@/api/axios'

export function sendCode(phone) {
    return axios({
        url: 'sms/sendCode',
        method: 'GET',
        headers: {
            // Authorization: token,
        },
        params: {
            phone
        }
    })
}
