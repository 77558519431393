import {createRouter, createWebHistory} from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView,
    },
    {
        path: '/about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
        },
        meta: {
            title: '关于我们'
        }
    },
    {
        path: '/demo',
        name: 'demo',
        redirect: '/',//默认页面
        children: [
            {
                path: '/demo/theme',
                name: 'theme',
                component: () => import('@/views/demo/Theme'),
            },
            {
                path: '/demo/color-card',
                name: 'color-card',
                component: () => import('@/views/demo/ColorCard'),
            },
            {
                path: '/demo/glass-card',
                name: 'glass-card',
                component: () => import('@/views/demo/GlassCard'),
            },
            {
                path: '/demo/text-underline',
                name: 'text-underline',
                component: () => import('@/views/demo/TextUnderline'),
            },
        ],
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})


// 默认路由
document.title = '千山竹海';

// 导航守卫，用于在路由切换时更新页面标题
router.beforeEach((to, from, next) => {
    // 如果路由有特定的标题，则添加到主标题中
    if (to.meta && to.meta.title) {
        document.title = '千山竹海 - ' + to.meta.title;
    } else {
        // 否则只显示主标题
        document.title = '千山竹海';
    }
    next();
});

export default router
