<template>
  <div class="container">
    <div class="content">
      <div class="left">
        <div class="title">
          <div>为初创公司</div>
          <div>创建一流的网站</div>
        </div>
        <div class="desc">
          欢迎来到我们的网站！我们是一家专注于为初创公司提供一流技术服务和解决方案的公司。无论您是正在创业还是已经初具规模，我们都能够为您提供最专业、最可靠的支持。
        </div>
      </div>
      <div class="right">
        <el-image :src="require('@/assets/images/home/serve/分析(3).svg')"/>
<!--        <el-image :src="require('@/assets/images/home/serve/商人(3).svg')"/>-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeIntroduce',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">


.container {
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  z-index: 1;
}

.content {
  /* 相对定位 */
  position: relative;
  border-radius: 15px;
  /* 溢出隐藏 */
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  max-width: 1440px;
  height: 100%;
}

.content .left {
  width: 40%;
  height: 357px;
}

.content .right {
  width: 60%;
  margin: 30px;
}

.content .title {
  font-size: 40px;
  text-align: left;
  margin: 0 20px 20px 20px;
}

.content .desc {
  text-indent: 2em;
  opacity: 0.5;
  font-size: 18px;
  text-align: left;
  margin: 100px 20px 20px 20px;
  vertical-align: bottom;
  color: inherit;
  text-decoration: none;
}


.text-underline:before {
  background: linear-gradient(315deg, #e8f5dd, #b9e795);
}

.el-image {
  width: 80%;
}
</style>
