import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
// import store from './store'
import 'element-plus/theme-chalk/index.css'
import ElementPlus from 'element-plus'
import "@element-plus/icons-vue"

import * as ElementPlusIconsVue from '@element-plus/icons-vue'

//自定义主题样式(未全部变换颜色)
import '@/styles/element/index.scss'

//之定义scss样式
import '@/styles/style.scss'

//暗黑模式
import 'element-plus/theme-chalk/dark/css-vars.css'
// 自定义暗黑样式
import '@/styles/theme/dark.css'

//国际化多语言
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'

const app = createApp(App);

app.use(router);
// app.use(ElementPlus);

app.use(ElementPlus, {
    locale: zhCn,
})


// 单独注册Element Plus图标
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component);
}

app.mount('#app');
